export const lightTheme = {
  background: '#fefefe',
  backgroundInv: '#001212',
  background2: '#fefefe',
  text: '#00171F',
  textInv: '#dddddd',
  default: '#999999',
  primary: '#00A8E8',
  secondary: '#0078b8',
  error: '#E84200',
  invert: 0,
  success: '#006600',
};
export const darkTheme = {
  background: '#001212',
  backgroundInv: '#004444',
  background2: '#122424',
  text: '#dddddd',
  textInv: '#ffffff',
  default: '#cccccc',
  primary: '#003459',
  secondary: '#006489',
  error: '#E84200',
  invert: 1,
  success: '#006600',
};
